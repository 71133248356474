<template>
    <div>
        <strong>Maintenance Photo Upload</strong>
        <p>Upload photos for this maintenance task. A minimum of 7 photos is required to complete the task.</p>
    </div>
</template>

<script>

import { fileUploadStore } from "@/store/FileUpload.store";

export default {
    name: 'MaintenancePhoto',
    data() {
        return {
            state: fileUploadStore.state
        }
    },
    created() {
        fileUploadStore.initialize();
    },
}

</script>